import MinimalLayout from 'layout/MinimalLayout';
import NavMotion from 'layout/NavMotion';
import Loadable from 'ui-component/Loadable';
import { lazy } from 'react';
import { customRoutes } from 'utils/routes';

const BmwProducts = Loadable(lazy(() => import('views/pages/bmwProductPage')));
const BmwTrack = Loadable(lazy(() => import('views/pages/bmwProductPage/trackingTool')));
const BmwTrackSearchBar = Loadable(lazy(() => import('views/pages/bmwProductPage/bmwTrackBar')));
console.log(customRoutes);
const BMWroutes = {
  path: '/',
  element: (
    <NavMotion>
      <MinimalLayout />
    </NavMotion>
  ),
  children: [
    ...customRoutes.map((route) => ({
      path: `/${route.name}`,
      element: <BmwTrack />
    })),
    {
      path: '/',
      element: <BmwTrackSearchBar />
      // element: <BmwProducts />
    },
    {
      path: '/trackingTool/:id',
      element: <BmwTrack />
    },
    // ...customRoutes.map((route) => ({ path: `/${route.name}`, element: <BmwTrack /> })),
    // {
    //   path: '/mickeyporsche',
    //   element: <BmwTrack />
    // },
    {
      path: '/trackingTool/*',
      element: <BmwTrackSearchBar />
    },
    {
      path: '/track'
    }
  ]
};

export default BMWroutes;
