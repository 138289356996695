export const customRoutes = [
  {
    serialId: 'GALPSH001',
    name: 'mickeyporsche'
  },
  {
    serialId: 'GALRS001',
    name: 'astonicecube'
  },
  {
    serialId: 'GALRS002',
    name: 'frauscherporsche'
  },
  {
    serialId: 'GALRS003',
    name: 'homagetochristo'
  },
  {
    serialId: 'GALRS004',
    name: 'alltimehero'
  },
  {
    serialId: 'GALRS005',
    name: 'prettybenz'
  }
];
